import lazyWithRetry from 'tools/lazyWithRetry';

const BlockRequestDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "blockRequestDashboardPage" */
	'../../pages/BlockRequest/BlockRequestDashboardPage'));

export default [
	{
		component: BlockRequestDashboardPage,
		exact: true,
		path: '/block_requests/:blockRequestId',
	},
];